<template>
  <div>
    <slot name="ButtonAction">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        @click="showModal = !showModal"
        variant="primary"
      >
        <feather-icon icon="PlusIcon" />
        Nuevo
      </b-button>
    </slot>
    <validation-observer ref="categoryForm">
      <b-modal
        id="modal-categories"
        v-model="showModal"
        :ok-title="modeEdit ? 'Actualizar' : 'Guardar'"
        cancel-title="Cancelar"
        centered
        :title="modeEdit ? 'Editar categoría' : 'Nueva categoría'"
        @ok="saveMetho"
        cancel-variant="light"
      >
        <b-overlay
          :show="loading"
          rounded
          responsive
          variant="transparent"
          opacity="0.33"
          blur="2px"
        >
          <b-form @submit.prevent="saveMetho">
            <b-form-group>
              <label for="nombre">Nombre:</label>
              <validation-provider
                #default="{ errors }"
                name="nombre"
                rules="required|min:3|max:20"
              >
                <b-form-input v-model="formData.name" @keypress="filterString" type="text" maxlength="25" placeholder="Nombre" required></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <label for="Descripcion">Descripción</label>
              <validation-provider
                #default="{ errors }"
                name="Descripción"
                rules="max:200"
              >
              <validation-provider #default="{ errors }" name="Descripción" rules="max:200">
  <b-form-textarea
    id="Descripcion"
    placeholder="Descripción"
    v-model="formData.description"
    maxlength="200"
    rows="3"
    max-rows="6"
  />
  
  <small class="text-danger">{{ errors[0] }} </small>
</validation-provider>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Colegio">
              <validation-provider #default="{ errors }" name="Colegio" rules="required">
                <v-select
                  v-model="formData.school_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="schoolOptions"
                  label="name"
                  :reduce="(item) => item.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-media class="mb-2">
              <template #aside>
                <b-avatar
                  ref="previewEl"
                  :src="image"
                  :variant="`light-success`"
                  size="250px"
                  rounded
                />
              </template>
              <div class="d-flex flex-wrap">
                <b-button variant="primary" @click="$refs.refInputEl.click()">
                  <input
                    ref="refInputEl"
                    type="file"
                    class="d-none"
                    @input="uploadImage"
                  />
                  <feather-icon icon="UploadIcon" class="d-inline mr-sm-50" />
                  <span class="d-none d-sm-inline">Subir</span>
                </b-button>
              </div>
            </b-media>
          </b-form>
        </b-overlay>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BOverlay,
  BAvatar,
  BMedia,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: {
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BOverlay,
    vSelect,
    BAvatar,
    BMedia,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  async mounted() {
    this.schoolOptions = await this.$store.dispatch("schools/index", { schools: true });
  },
  data() {
    return {
      image: null,
      schoolOptions: [],
      showModal: false,
      formData: {
        image: null,
      },
      loading: false,
      modeEdit: false,
      page: 1,
    };
  },
  watch: {
    showModal(val) {
      if (!val) {
        this.formData = {
          name: null,
          description: null,
          image: null,
        };
        this.modeEdit = false;
      }
    },
  },
  methods: {
    filterString(event) {
      const keyCode = event.which ? event.which : event.keyCode;
        if (
          (keyCode >= 48 && keyCode <= 57) ||  
          keyCode === 33 || keyCode === 64 || keyCode === 35 ||  
          keyCode === 36 || keyCode === 37 || keyCode === 94 ||
          keyCode === 38 || keyCode === 42
        ) {
        event.preventDefault(); 
      }
    },
    uploadImage() {
      const { inputImageRenderer } = useInputImageRenderer(
        this.$refs.refInputEl,
        this.getImage
      );

      inputImageRenderer();
    },
    getImage(base64) {
      this.image = base64;
      this.formData.image = base64;
    },
    show() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    editMetho(data, page) {
      this.page = page;
      this.image = data.image;
      this.modeEdit = true;
      this.formData = {
        id: data.id,
        name: data.name,
        description: data.description,
        school_id: data.school_id,
      };
      this.showModal = true;
    },
    async saveMetho(bvModalEvt) {
      try {
        bvModalEvt.preventDefault();

        if (!(await this.$refs.categoryForm.validate())) {
          return;
        }
        this.loading = true;

        if (this.modeEdit) {
          if (this.image) {
            await this.$store
              .dispatch("products/updateCategory", this.formData)
              .then(() => {
                this.image = null;
              });
          } else {
            await this.$store
              .dispatch("products/updateCategory", {
                id: this.formData.id,
                name: this.formData.name,
                description: this.formData.description,
                school_id: this.formData.school_id,
              })
              .then(() => {
                this.image = null;
              });
          }
        } else {
          await this.$store.dispatch("products/storeCategory", this.formData).then(() => {
            this.image = null;
          });
        }
        this.$emit("refeshData");
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Éxito",
            icon: "CheckCircleIcon",
            variant: "success",
            text: `Categoría ${this.modeEdit ? "actualizada" : "creada"} correctamente`,
          },
        });
        this.showModal = false;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "AlertCircleIcon",
            variant: "danger",
            text: "Error al crear la categoría",
          },
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
